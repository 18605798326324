import React, { useEffect, useState } from 'react';
import { authClient, getUser, isAuthenticated } from 'utils/auth';
import { PopupTimeoutError } from '@auth0/auth0-spa-js';
import useStore from 'utils/store';
import { Countries } from 'utils/constants';

enum Provider {
  AUTH0 = 'Username-Password-Authentication',
  GOOGLE = 'core-ops-google-social-connection',
}

export const decideAuth0ConnectionType = (sub: string): Provider => {
  const [provider] = sub.split('|');
  return provider === 'auth0' ? Provider.AUTH0 : Provider.GOOGLE;
};

const withMFA = (Wrapped) => {
  return (props) => {
    const [ready, setReady] = useState(false);
    const [error, setError] = useState('');
    const { country } = useStore();

    const onLoad = async (): Promise<void> => {
      try {
        const authenticated = await isAuthenticated();
        if (!authenticated) {
          const user = await getUser();
          const provider = decideAuth0ConnectionType(user?.sub || '');

          await authClient.getTokenWithPopup({
            connection: provider,
            prompt: 'select_account',
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: `read:employee_activity:all`,
            max_age: 7200,
          });
        }

        setReady(true);
      } catch (err: any) {
        if (err instanceof PopupTimeoutError) {
          err.popup.close();
        }
        setError(err?.message);
        throw err;
      }
    };

    useEffect(() => {
      if (country === Countries.NL || process.env.REACT_APP_ENV === 'staging') {
        setReady(true);
      } else {
        onLoad();
      }
    }, [country]);

    return ready ? (
      <Wrapped {...props} />
    ) : error ? (
      <span className="p-4 text-pink text-xs">{error}</span>
    ) : (
      <></>
    );
  };
};

const WithMFAWrapperFC = ({
  children,
}: React.PropsWithChildren): JSX.Element => {
  return <>{children}</>;
};

export const WithMFAWrapper = withMFA(WithMFAWrapperFC);

export default withMFA;
