import { forwardRef } from 'react';
import {
  Icon,
  Table,
  TableSortBy,
  TableVariant,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@hubportal/components';
import { useTranslation } from 'react-i18next';
import { CalculatedTimeSince } from './calculate-time-since';
import { JobTitles, RiderStates, RiderTableColumns } from 'utils/constants';
import { Rider } from 'src/models/rider';
import {
  capitalizeOrUppercaseJobTitle,
  convertToTimeRange,
  formatPunchInTime,
  getRiderActivityColor,
  isShiftStartLaterThanNow,
  timeToMinutesFormatter,
  formatNumberToPercentage,
} from 'utils/helpers';
interface RiderTableProps {
  data: Rider[];
  sortBy: TableSortBy;
  onSortChange: (sb: TableSortBy) => void;
  isPerformanceTabEnabled: boolean;
}

const RidersTable = forwardRef<HTMLDivElement, RiderTableProps>(
  ({ data, sortBy, onSortChange, isPerformanceTabEnabled }, ref) => {
    const { t } = useTranslation();

    const activeColumns = Object.values(RiderTableColumns);

    const renderCellContent = (
      employee: Rider,
      column: string
    ): JSX.Element => {
      switch (column) {
        case RiderTableColumns.NAME:
          return (
            <div
              className="hover:cursor-pointer"
              onClick={() => {
                window.open(
                  `/workforce/${employee.auth0_id}/${
                    isPerformanceTabEnabled ? 'performance' : ''
                  }`,
                  '_blank'
                );
              }}
            >
              <div className="text-base flex items-center mb-2 w-36">
                {employee.job_title && (
                  <span className="mr-1 text-xs font-normal">
                    [{capitalizeOrUppercaseJobTitle(employee.job_title)}]
                  </span>
                )}
                <span className="truncate">{employee.name}</span>
              </div>
              {employee.shift_start &&
                employee.shift_end &&
                employee.job_title !== JobTitles.EXT && (
                  <div className="text-[#BFBFBF] text-sm font-normal">
                    {convertToTimeRange(
                      employee.shift_start,
                      employee.shift_end
                    )}
                  </div>
                )}
            </div>
          );
        case RiderTableColumns.ACTIVITY:
          return (
            <div className="flex flex-col">
              {employee.activity && (
                <div className="flex items-baseline mb-2 text-base font-normal w-[104px]">
                  <span
                    className={`w-2 h-2 rounded mr-2 shrink-0 ${getRiderActivityColor(
                      employee.activity
                    )}`}
                  />
                  <span className="truncate">
                    {t(
                      `employee_metrics.rider.activities.${employee.activity}`
                    )}
                  </span>
                </div>
              )}

              {employee.in_state_since &&
                employee.activity !== RiderStates.OFFLINE && (
                  <div>
                    <div className="text-[#BFBFBF] text-sm font-normal">
                      <CalculatedTimeSince
                        inStateSince={employee.in_state_since}
                      />
                    </div>
                  </div>
                )}
            </div>
          );
        case RiderTableColumns.PUNCH_IN:
          return (
            <div className="flex h-full items-center">
              {employee?.punch_in ? (
                <div>{formatPunchInTime(employee.punch_in)}</div>
              ) : !isShiftStartLaterThanNow(employee.shift_start as string) ? (
                <div className="py-1 px-2 bg-orange-400 text-gray rounded">
                  {t(`employee_metrics.oa_table.col.missing`)}
                </div>
              ) : null}
            </div>
          );
        case RiderTableColumns.AVG_TIME_AT_CUSTOMER_IN_SECONDS:
          return (
            <>
              {employee.avg_time_at_customer_in_seconds && (
                <div className="flex h-full items-center">
                  {timeToMinutesFormatter(
                    employee.avg_time_at_customer_in_seconds
                  )}
                </div>
              )}
            </>
          );
        case RiderTableColumns.ACCEPTANCE_RATE:
          return (
            <div className="flex h-full items-center">
              {formatNumberToPercentage(employee.acceptance_rate ?? 0)}
            </div>
          );
        case RiderTableColumns.TRIP_ON_TIME_COMPLIANCE:
          return (
            <div className="flex h-full items-center">
              {formatNumberToPercentage(employee.trip_on_time_compliance ?? 0)}
            </div>
          );
        case RiderTableColumns.UNACCOUNTED_TIME_OFFLINE:
          return (
            <div className="flex h-full items-center">
              {formatNumberToPercentage(
                employee.unaccounted_time_offline_rate ?? 0
              )}
            </div>
          );
        case RiderTableColumns.DELIVERIES_PER_HOUR:
          return (
            <div className="flex h-full items-center">
              {employee.deliveries_per_hour
                ? employee.deliveries_per_hour.toFixed(2)
                : 0}
            </div>
          );
        default:
          return (
            <div className="flex h-full items-center">{employee[column]}</div>
          );
      }
    };

    const renderRow = (employee: Rider): JSX.Element[] =>
      activeColumns.map((column) => (
        <Td key={column} selected={false} hoverable={false}>
          <span className="text-base flex h-full items-center hover:cursor-default">
            {renderCellContent(employee, column)}
          </span>
        </Td>
      ));

    const renderHeaders = (): JSX.Element[] =>
      activeColumns.map((column) => (
        <Th key={column} name={column} sortable>
          <div className="mr-4 w-[80px] whitespace-normal overflow-wrap">
            {t(`employee_metrics.rider_table.${column}`)}
          </div>
        </Th>
      ));

    return (
      <div className="mt-8">
        <div className="mb-4 flex">
          <Icon type="bicycle" />
          <span className="ml-2" data-testid="ridingTable">
            {t(`employee_level_metrics.option_filter.rider`)}
          </span>
        </div>
        <div className="flex flex-col pr-4" ref={ref}>
          <Table
            variant={TableVariant.secondary}
            sortLabels={{
              asc: t('sort_ascending'),
              desc: t('sort_descending'),
            }}
            sortBy={sortBy}
            onSortChange={onSortChange}
            gridTemplateColumns={`184px ${'136px '.repeat(
              activeColumns.length ? activeColumns.length - 2 : 0
            )}minmax(120px, 1fr)`}
          >
            <Thead>
              <Tr>{renderHeaders()}</Tr>
            </Thead>
            <Tbody>
              {data &&
                data.map((employee, i) => (
                  <Tr
                    key={i}
                    hoverable={false}
                    variant={i % 2 === 0 ? 'default' : 'background-color'}
                  >
                    {renderRow(employee)}
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </div>
      </div>
    );
  }
);

export default RidersTable;
