export enum Countries {
  DE = 'DE',
  NL = 'NL',
  FR = 'FR',
}

export enum ChecksTypes {
  'STOCK_CHECK_P0' = 'stock_check_p0',
  'FRESH_CHECK' = 'fresh_check',
  'BBD_CHECK' = 'bbd_check',
  'STOCK_CHECK_P1' = 'stock_check_p1',
  'MANUAL_CHECK' = 'manual_check',
}

export enum ChecksSections {
  'URGENT' = 'urgent',
  'ROUTINE' = 'routine',
  'OTHER' = 'other',
}

export enum ChecksDeadlineTypes {
  'SLA' = 'sla',
  'CUT_OFF' = 'cutoff',
  'COMPLETED' = 'completed',
}

export enum OAStates {
  INBOUNDING = 'inbounding',
  INVENTORY = 'inventory',
  PICKING = 'picking',
  RESTOCKING = 'restocking',
  HUB_ACTIVITIES = 'hub_activities',
  IDLE = 'idle',
  BREAK = 'break',
  ON_BREAK = 'on_break',
  OFFLINE = 'offline',
}

export const OAStatesColors = {
  [OAStates.INBOUNDING]: 'bg-sky-400',
  [OAStates.INVENTORY]: 'bg-lime-300',
  [OAStates.PICKING]: 'bg-pink-300',
  [OAStates.RESTOCKING]: 'bg-violet-300',
  [OAStates.HUB_ACTIVITIES]: 'bg-orange-400',
  [OAStates.IDLE]: 'bg-orange-400',
  [OAStates.OFFLINE]: 'bg-gray-300',
};

export enum OATableColumns {
  NAME = 'name',
  ACTIVITY = 'activity',
  PUNCH_IN = 'punch_in',
  HOURS_WORKED = 'hours_worked',
  BREAK_DURATION = 'break_duration',
  ORDERS_PICKED = 'orders_picked',
  AVG_PICKING_SPEED_PER_ITEM_IN_SECONDS = 'avg_picking_speed_per_item_in_seconds',
  PRE_DELIVERY_ISSUE_RATE = 'pre_delivery_issue_rate',
  INBOUNDED_SKUS = 'inbounded_skus',
  CHECKED_SKUS = 'checked_skus',
  RESTOCKED_SKUS = 'restocked_skus',
}

export enum RiderTableColumns {
  NAME = 'name',
  ACTIVITY = 'activity',
  PUNCH_IN = 'punch_in',
  HOURS_WORKED = 'hours_worked',
  BREAK_DURATION = 'break_duration',
  ORDERS_DELIVERED = 'orders_delivered',
  DELIVERIES_PER_HOUR = 'deliveries_per_hour',
  ACCEPTANCE_RATE = 'acceptance_rate',
  AVG_TIME_AT_CUSTOMER_IN_SECONDS = 'avg_time_at_customer_in_seconds',
  TRIP_ON_TIME_COMPLIANCE = 'trip_on_time_compliance',
  UNACCOUNTED_TIME_OFFLINE = 'unaccounted_time_offline',
}

export enum RiderStates {
  ONLINE = 'online',
  IDLE = 'idle',
  DECLINING = 'declining',
  ON_BREAK = 'on_break',
  BREAK = 'break',
  LOGOUT = 'logout',
  LARGE_ORDER_SUPPORT = 'large_order_support',
  CUSTOMER_ORDER_REDELIVERY = 'customer_order_redelivery',
  HUB_ONE_TASKS = 'hub_one_tasks',
  INBOUNDING = 'inbounding',
  SHELF_RESTOCKING = 'shelf_restocking',
  EQUIPMENT_ISSUE = 'equipment_issue',
  TEMP_OFFLINE = 'temp_offline',
  EMPLOYEE_ONBOARDING = 'employee_onboarding',
  ACCIDENT = 'accident',
  RETURNING = 'returning',
  BUSY = 'busy',
  OFFLINE = 'offline',
  STARTING = 'starting',
}

export const RiderStateColors = {
  [RiderStates.ONLINE]: 'bg-green-400',
  [RiderStates.IDLE]: 'bg-orange-400',
  [RiderStates.DECLINING]: 'bg-rose-600',
  [RiderStates.ON_BREAK]: 'bg-gray-300',
  [RiderStates.LOGOUT]: 'bg-gray-300',
  [RiderStates.BREAK]: 'bg-orange-400',
  [RiderStates.LARGE_ORDER_SUPPORT]: 'bg-orange-400',
  [RiderStates.HUB_ONE_TASKS]: 'bg-orange-400',
  [RiderStates.SHELF_RESTOCKING]: 'bg-gray-300',
  [RiderStates.EQUIPMENT_ISSUE]: 'bg-orange-400',
  [RiderStates.ACCIDENT]: 'bg-orange-400',
  [RiderStates.RETURNING]: 'bg-pink-200',
  [RiderStates.BUSY]: 'bg-pink-300',
  [RiderStates.OFFLINE]: 'bg-gray-300',
};

// The oa's activities sorting will be done based on the order provided here.
export const OaActivityOrder = [
  'idle',
  'break',
  'picking',
  'inbounding',
  'inventory',
  'restocking',
  'hub_activities',
  'offline',
];

// The rider's activities sorting will be done based on the order provided here.
export const RiderActivityOrder = [
  'online',
  'idle',
  'declining',
  'on_break',
  'logout',
  'break',
  'large_order_support',
  'customer_order_redelivery',
  'hub_one_tasks',
  'shelf_restocking',
  'inbounding',
  'picking',
  'employee_onboarding',
  'equipment_issue',
  'accident',
  'returning',
  'busy',
  'auto_punched_out',
  'bike_unassigned',
];

export enum JobTitles {
  EXT = 'ext',
  SL = 'sl',
  HM = 'hm',
  OA_PLUS = 'oa+',
}
